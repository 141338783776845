<template>
  <div class="my-second xuqiu">
      <Head :title="$t('add.replay')" :show="true" :right="true">
          <van-icon name="service-o" size="20" @click="flag=!flag"  :style="{color:'#fff'}" />
      </Head>
      <div class="common-box xuqiu-box" v-if="show">

          <div class="xuqiu-list" >
              <div class="xuqiu-list-item" >
                  <div class="xuqiu-list-item-top">
                      <div class="xuqiu-list-item-top-left">
                         <p class="error-cate ">{{question.category_name}}</p>
                         <p class="error-status " :class="'status'+(question.status)">
                             <span v-if="question.status==0">{{$t('xuqiu.not_resolve')}}</span>
                             <span v-if="question.status==1">{{$t('xuqiu.have_resolve')}}</span>
                             <span v-if="question.status==2">{{$t('xuqiu.loading_resolve')}}</span>
                         </p>
                      </div>
                      <p class="error-time">
                        {{question.created_at | dateFormat('yyyy-MM-DD hh:mm:ss')}}
                      </p>
                  </div>
                  <h3 class="error-title">{{question.subject}}</h3>
              </div>


               <div class="xuqiu-list-item" v-for="(l,i) in chat" :key="i"  >
                  <div class="xuqiu-list-item-top">
                      <div class="xuqiu-list-item-top-left">
                         <p class="error-cate " v-if="l.qtype==1">{{l.username}} </p>
                         <p class="error-status " v-if="l.qtype==1"  :class="'status1'">
                             <span >{{$t('add.mine')}}</span>
                         </p>

                        <p class="error-cate " v-if="l.qtype==2">{{l.account_username}} </p>
                        <p class="error-status " v-if="l.qtype==2"  :class="'status2'">
                            <span >{{$t('add.kefu')}}</span>
                        </p>
                      </div>
                      <p class="error-time">
                         {{l.created_at | dateFormat('yyyy-MM-DD hh:mm:ss')}}
                      </p>
                  </div>
                  <div class="error-content">
                     {{l.content}}
                  </div>
                  <div class="error-imgs" v-if="l.picture">
                    <img :src="l.picture" alt="">
                  </div>
              </div>
          </div>



          <van-popup v-model="flag"  
            closeable
            close-icon="close"
            position="bottom"
            :style="{ height: '7rem' ,padding:'40px 20px 10px 20px' }"
        >
             <div class="add-need">
                <van-form class="addform form" ref="addform" @submit="addNeedList">
                  <van-field
                    v-model="content"
                    name="content"
                    rows="4"
                    autosize
                    type="textarea"
                    class="addtext"
                    :placeholder="$t('xuqiu.content')"
                    show-word-limit
                    :rules="[{ required: true, message: $t('xuqiu.input_content') }]"
                  >
                  </van-field>

              </van-form>
               
          </div>
          <van-button class="add-btn" @click="todoSubmit">{{$t('add.replay')}}</van-button>
      </van-popup>
      </div>
      
  </div>
</template>


<script>
import {Notify} from 'vant'
export default {
    data(){
        return {
            question:{},
            show:false,
            chat:[],
            flag:false,
            content:""
        }
    },
    methods:{
        addNeedList(value){
            this.$ajax.supportchatsumit({
                content:this.content,
                qid:this.$route.query.qid
            })
            .then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg });
                    this.flag = false;

                    this.$ajax.supportchat({
                        qid:this.$route.query.qid
                    })
                    .then(res=>{
                        if(res.code==200){
                            this.question = res.data.question;
                            this.chat = res.data.chat.reverse()
                        }
                    })
                }
            })
        },
        todoSubmit(){
        // 提交需求
            this.$refs.addform.submit()

        },
    },
    mounted(){
        this.$ajax.supportchat({
            qid:this.$route.query.qid
        })
        .then(res=>{
            if(res.code==200){
                this.question = res.data.question;
                this.chat = res.data.chat.reverse()
                this.show = true;
            }
        })
    }
}
</script>
